import React from "react";
import styled from '@emotion/styled'
const Container = styled.section`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 300px;`
export default function () {
  return (
    <Container >
      <h1>
        Edit About component or pages/about.jsx to include your information.
        </h1>
    </Container>
  );
}


